import React, {useState} from 'react'
import db from './firebase'
const tinyid = require('tiny-unique-id')

function Input() {
    const [input, setInput] = useState('')
    const [shorten, setShortrn] = useState('')
    const [showShorten, setShowShorten] = useState(false)

    const handleDb = async(e) => {
        e.preventDefault()
        
        // List of allowed keywords
        const allowedKeywords = [
          "189230", //Ahsan Habib
          "9hxwkwz",
          "210665",
          "191441",
          "192356",
          "182882", //jahirul vai
          "182847",
          "176457",
          "164316",
          "164855",
          "192517",
          //"183156",
          "206641", //sabuz
          "210080",
          "211906",
          "212002",
          "182242", //imran
          "189572",
          "189574",
          "188289",
          "187089",
          "183190",
          "180284",
          "190646",
          "190637",
          "182629",
          "168792",
          "183190",
          "191662",
          "170068",
          "192787",
          "akpiPaZNuW48tx",
          "182012",
          "191824", //Asad
          "190964",
          "190221",
          "192233",
          "x8EhYy15s3okUA",
          "eRTW6d9TDNyTA",
          "178709", //Ridoy
          "191287",
          "172533",
          "183082", //rishad v imran
          "182357", // v imran 
          "189821", // Shuvo
          "188152",
          "159713", // Shamin
	]
        
        // List of blocked domains
        const blockedDomains = ["v.ht", "gg.gg", "bit.do", "ourl.in", "korta.st", "zzb.bz"]
        
        // Validate the input URL against the keywords
        const isValidUrl = allowedKeywords.some(keyword => input.includes(keyword))
        if (!isValidUrl) {
            alert('Your CPA profile is not registered here')
            return
        }
        
        // Validate the input URL against the blocked domains
        const url = new URL(input)
        if (blockedDomains.includes(url.hostname)) {
            alert('বাপের সাথে পাকনামি করতে এসোনা বাচ্চা')
            return
        }
        
        const slug = tinyid.unique()
        await db.collection('urls').add({
            url: input,
            slug: slug
        })
        setShortrn(`${window.location.origin}/${slug}`)
        setShowShorten(true)
    }

    const handleCopy = async() => {
        try {
            await navigator.clipboard.writeText(shorten)
            alert('Link copied to clipboard!')
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }

    return (
        <div className='container'>
		<center>
            <h1 className="mt-5">
        <a
          href={`${window.location.origin}`}
          style={{ textDecoration: 'none', color: 'green' }}
        >
          URL SHORTENER
        </a>
      </h1>
            <form onSubmit={handleDb}>
                <div className='mt-4'>
                    <input type="url" value={input} onChange={e=> setInput(e.target.value)} className='form-control mt-3' placeholder='Enter URL' />
                    <button type='submit' className='btn btn-dark mt-3'>Shorten URL</button>
                    {showShorten &&
                        <div className="mt-3">
                            <input type="text" className='form-control' value={shorten} readOnly />
                            <button type='button' onClick={handleCopy} className='btn btn-primary mt-3'>Copy Link</button>
                        </div>
                    }
                </div>
            </form>
			<div className="container">
  {/* existing code 
  <div className="social-links">
  <a href="https://www.facebook.com/rakhib420"><i className="fab fa-facebook"></i></a>
  <a href="https://api.whatsapp.com/send?phone=8801797951359&text=Hello,%20I'm%20interested%20on%20your%20services."><i className="fab fa-whatsapp"></i></a>
</div> */}
</div>
</center>
        </div>
    )
}

export default Input